import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
import mysaControls from "../images/mysa-controls.webp";
import mysaIcons from "../images/mysa-icons.webp";

const h1ClassNames = "text-center font-bold text-3xl pb-5";
const h2ClassNames = "italic font-bold text-2xl pt-4";
const pClassNames = "pt-4 pl-2 text-lg";
const buttonImageClasses = "flex pl-2 pr-2 pt-2 pb-2 w-full ";

const HvacInstructionsPage = () => {
  return (
    <Layout>
      <WhiteCard>
        <section>
          <h1 className={h1ClassNames}>Heat & A/C Operating Instructions</h1>
          <p className={pClassNames}>
            The Overlook Chalet uses a state-of-the-art Mitsubishi heat pump
            system for both <b>heating</b> and <b>cooling</b>. Each room has
            it's own climate control.
          </p>
          <h2 className={h2ClassNames}>Basic Operation</h2>
          <p className={pClassNames}>
            Please use the Mysa<sup>&reg;</sup> smart thermostats in each room
            to adjust the temperature. You'll hear the mini-split unit beep when
            your changes are registered.
          </p>
          <img
            className={buttonImageClasses}
            src={mysaControls}
            alt="power-button"
          />
          <img
            className={buttonImageClasses}
            src={mysaIcons}
            alt="power-button"
          />
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default HvacInstructionsPage;
